import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4-4-4-4 to a 4RM`}</p>
    <p>{`Pendlay Rows 4-4-4-4 to a 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 50ft HS Walk (scale to 100ft Bear Crawl)`}</p>
    <p>{`5 Rounds of:`}</p>
    <p>{`5-HSPU’s`}</p>
    <p>{`7-Ring Dips`}</p>
    <p>{`9-L Pullups`}</p>
    <p>{`11-Back Extensions`}</p>
    <p>{`Cash Out: 50ft HS Walk (scale to 100ft Bear Crawl)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      